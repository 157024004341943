<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="row">
            <div class="col-md-12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">{{
              userAction
            }}</span>
                        </h3>
                        <div class="card-toolbar">
                            <a
                                    @click="goList"
                                    class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                            >
                                Volver
                            </a>
                        </div>
                    </div>
                    <div class="card-body pb-10 pb-2">
                        <!-- <p>
                            Be sure to use an appropriate <code>type</code> on all inputs
                            (e.g., email for email address or number for numerical
                            information) to take advantage of newer input controls like
                            <code>email</code> verification, <code>number</code> selection,
                            and more.
                          </p> -->
                        <div>
                            <b-form @submit="onSubmitTraining" @reset="onReset" v-if="show">
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group
                                                id="input-group-name"
                                                label="Nombre"
                                                label-for="input-1"
                                        >
                                            <b-form-input
                                                    id="input-1"
                                                    v-model="form.name"
                                                    type="text"
                                                    required
                                                    placeholder="Escribe el nombre"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Imagen"
                                                label-for="input-3"
                                        >
                                            <b-form-file v-if="selectedItem"
                                                         v-model="form.image"
                                                         :state="Boolean(form.image)"
                                                         placeholder="Cargar nuevo archivo ..."
                                                         @change="onImageChange($event)"
                                            ></b-form-file>
                                            <b-form-file v-else
                                                         v-model="form.image"
                                                         :state="Boolean(form.image)"
                                                         placeholder="Cargar nuevo archivo ..."
                                                         required
                                                         @change="onImageChange($event)"
                                            ></b-form-file>
                                            <div class="mt-3">
                                                Fichero seleccionado:
                                                {{ form.image ? form.image.name : "" }}
                                            </div>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3" v-if="actualImageResource">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Vista previa"
                                                label-for="input-3"
                                        >
                                            <img :src="actualImageResource" class="image-view" alt=""/>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group
                                                id="input-group-description"
                                                label="Descripción"
                                                label-for="input-2"
                                        >
                                            <b-textarea
                                                    id="input-2"
                                                    v-model="form.description"
                                                    rows="2"
                                                    required
                                                    placeholder="Escribe el nombre"
                                            ></b-textarea>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-3">
                                        <b-form-group
                                                id="input-group-3"
                                                label="Estado"
                                                label-for="input-3"
                                        >
                                            <b-form-select
                                                    id="input-3"
                                                    v-model="form.status"
                                                    :options="statusList"
                                                    required
                                            ></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" data-app>
                                        <b-form-group
                                                id="input-group-description"
                                                label="Cursos"
                                                label-for="input-2"
                                        >
                                            <v-text-field
                                                    v-model="dtSearch"
                                                    label="Buscar"
                                                    append-icon="search"
                                                    class="mx-4"
                                                    single-line
                                                    hide-details
                                            ></v-text-field>
                                            <v-data-table
                                                    v-model="courseSelected"
                                                    :headers="headers"
                                                    :items="courseList"
                                                    :options.sync="options"
                                                    item-key="id"
                                                    show-select
                                                    :server-items-length="courseTotalRows"
                                                    :loading="isLoadingTable"
                                                    :footer-props="{
                                                        itemsPerPageOptions: [5, 10, 25, 50],
                                                        showFirstLastPage: true,
                                                        itemsPerPageText: 'Resultados por página'
                                                    }"
                                                    :items-per-page="itemsPerPage"
                                                    class="elevation-1"
                                                    locale="es"
                                            >
                                                <template v-slot:item.image="{ item }">
                                                    <div class="">
                                                        <img
                                                                :src="item.image"
                                                                class="image-table medium align-self-center"
                                                                alt=""
                                                        />
                                                    </div>
                                                </template>
                                                <template slot="no-data">
                                                    Ningún dato para mostrar
                                                </template
                                                >
                                            </v-data-table>
                                        </b-form-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <b-button type="submit" variant="primary">Guardar</b-button>
                                        <b-button class="ml-2" type="reset" variant="danger">
                                            Resetear
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {STORE_TRAINING} from "@/store/modules/trainings.module";
    import {UPDATE_TRAINING} from "@/store/modules/trainings.module";
    import {TRAINING_GET_COURSES_LIST} from "@/store/modules/trainings.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "TrainingsForm",
        data() {
            return {
                courseSelected: [],
                isLoading: false,
                isLoadingTable: false,
                options: {},
                form: {
                    name: "",
                    description: "",
                    status: 1,
                    courses: [],
                },
                currentPage: 1,
                itemsPerPage: 5,
                editorOption: {
                    // Some Quill options...
                },
                headers: [
                    {
                        text: "Imagen",
                        value: "image",
                        align: "start",
                        sortable: false,
                        width: 100,
                    },
                    {
                        text: "Nombre",
                        value: "name",
                        align: "start",
                        sortable: false,
                        width: 250,
                    },
                    {text: "Descripción", value: "description", sortable: false},
                ],
                userAction: "Nuevo entrenamiento",
                statusList: [
                    {text: "Activo", value: 1},
                    {text: "Inactivo", value: 2},
                ],
                actualImageResource: "",
                show: true,
                dtSearch: ""
            };
        },
        mounted() {
            this.$store.dispatch(TRAINING_GET_COURSES_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage,
                search: this.dtSearch
            });
            if (this.selectedItem == null) {
                this.userAction = "Nuevo entrenamiento";
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Trainings", route: "#"},
                    {title: this.userAction},
                ]);
            } else {
                this.userAction = "Modificando entrenamiento";
                this.$store.dispatch(SET_BREADCRUMB, [
                    {title: "Trainings", route: "#"},
                    {title: this.userAction},
                ]);

                this.form.name = this.selectedItem.name;
                this.form.description = this.selectedItem.description;
                this.form.status = this.selectedItem.status;
                this.actualImageResource = this.selectedItem.image;

                this.courseSelected = this.selectedItem.courses
            }
        },
        methods: {
            onSubmitTraining($event) {
                this.isLoading = true
                $event.preventDefault()

                this.form.courses = this.courseSelected.map(function (value) {
                    return value.id;
                });

                let scope = this;

                if (scope.form.image instanceof File) {

                    const formData = new FormData();
                    formData.append("file", scope.form.image);
                    formData.append("azure_container", "icons-systems");

                    ApiService.sendImage(formData)
                        .then((response) => {
                            let storeUrl = response.data.url
                            if (typeof storeUrl === 'string' || storeUrl instanceof String) {
                                scope.createOrUpdateTraining(scope, storeUrl)
                            } else {
                                console.error("No se obtuvo una url valida del almacenamiento de la imagen.")
                                scope.isLoading = false
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else {
                    scope.createOrUpdateTraining(scope, scope.actualImageResource)
                }
            },
            createOrUpdateTraining(scope, storeUrl) {
                let traningData = {
                    name: scope.form.name,
                    image: storeUrl,
                    description: scope.form.description,
                    status: scope.form.status,
                    courses: scope.form.courses,
                };
                if (scope.selectedItem == null) {
                    scope.$store.dispatch(STORE_TRAINING, traningData).then(() => {
                        scope.isLoading = false
                        scope.goList();
                    });
                } else {
                    traningData.id = scope.selectedItem.id;
                    scope.$store.dispatch(UPDATE_TRAINING, traningData).then(() => {
                        scope.isLoading = false
                        scope.goList();
                    });
                }
            },
            onReset() {
            },
            goList() {
                this.$router.push("trainings");
            },
            onImageChange($event) {
                const file = $event.target.files[0];
                this.actualImageResource = URL.createObjectURL(file);
            },
            getTrainingCourseList(dataPagination){
                this.$store.dispatch(TRAINING_GET_COURSES_LIST, {
                    page: dataPagination.page,
                    itemsPerPage: dataPagination.itemsPerPage,
                    search: this.dtSearch
                })
            }
        },
        computed: {
            courseTotalRows() {
                return this.$store.state.trainings.coursesListTotal;
            },
            courseList() {
                return this.$store.state.trainings.coursesList;
            },
            selectedItem() {
                return this.$store.state.trainings.selectedTraining;
            },
        },
        watch: {
            options: {
                handler (dataPagination) {
                    this.getTrainingCourseList(dataPagination)
                },
                deep: true,
            },
            dtSearch(){
                this.getTrainingCourseList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            },
        },
    };
</script>

<style>
    .v-data-footer__select .v-select{
        padding: 15px!important;
    }
</style>
